<template>
  <el-dialog
    v-model="_isShow"
    width="90%"
    top="0"
    lock-scroll
    center
    show-close
    destroy-on-close
    :title="$t('calculation_review')"
  >
    <div>
      <div></div>
      <div class="mb-6">{{ content }}</div>

      <el-button type="primary" class="w-full" @click="closeDialog">
        {{ $t('cancel') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import BarcodeReaderMixin from '@/components/mixins/BarcodeReaderMixin.vue'
import { Options } from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'

@Options({
  emits: ['update:isShow'],
  name: 'ReviewCalculationDialog',
})
export default class ReviewCalculationDialog extends BarcodeReaderMixin {
  @PropSync('isShow', { type: Boolean }) _isShow!: boolean
  @Prop({ type: String }) readonly content?: string

  closeDialog() {
    this._isShow = false
  }
}
</script>
<style lang="scss">
@import './src/assets/css/mixins.scss';

.edit-view.view {
  padding-left: 0;
  padding-right: 0;
}
</style>
