
import BarcodeReaderMixin from '@/components/mixins/BarcodeReaderMixin.vue'
import { Options } from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'

@Options({
  emits: ['update:isShow'],
  name: 'ReviewCalculationDialog',
})
export default class ReviewCalculationDialog extends BarcodeReaderMixin {
  @PropSync('isShow', { type: Boolean }) _isShow!: boolean
  @Prop({ type: String }) readonly content?: string

  closeDialog() {
    this._isShow = false
  }
}
